@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../font/Lato-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../font/Lato-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Lato-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../font/Lato-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../font/Lato-BlackItalic.ttf') format('truetype');
}