html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}
h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 72px;
    margin-top: 50px;
}

#nft-gift-intro {
    color: #1a464f;
    font-weight: 900;
    font-size: 72px;
    display: block;
}

#nft-gift-intro-thin {
    font-weight: 300;
    color: #1a464f;
}
h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    color: #1a464f;
    text-align: center;
}

h3 {
    color: #1a464f;
    font-weight: 900;
    font-size: 30px;
    line-height: 62px;
    margin-bottom: 55px;
}

p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    margin-bottom: 0;
    background-color: transparent;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.site-logo {
    font-size: 18px;
    font-weight: bold;
    text-indent: -9999px;
    line-height: 32px;
    color: #1a464f;
    background-image: url('../image/logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 197px;
    height: 49px;
    display: block;
}
footer .site-logo {
    background-image: url('../image/logo-white.svg');
}
.logo-wrapper {
    padding-top: 25px;
    padding-bottom: 22px;
}
.site-header {
    background: linear-gradient(144.7deg, #d0eef2 13.27%, #b0dce1 79.27%);
    position: relative;
}
.site-header::after {
    background: url(../image/gift-left.png), url(../image/gift-top.png),
        url(../image/gift-top-right.png), url(../image/gift-bottom-right.png);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat;
    background-position: -4% center, center top, top right, right bottom;
    background-size: 100px, 165px, 300px, 300px;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
}
.site-header .container {
    position: relative;
    z-index: 1;
}
.site-header-inner {
    border-radius: 29px;
    position: relative;
    padding-bottom: 100px;
}
.site-header-inner p {
    font-size: 18px;
    color: #2f6a76;
    line-height: 32px;
}

.header-description {
    margin-bottom: 60px;
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
    color: #2f6a76;
}

.nav-wrapper ul {
    display: flex;
}

.nav-wrapper li {
    border-radius: 50px;
    box-shadow: 0 4px 33px 6px rgb(0 0 0 / 10%);
    height: 65px;
    margin-right: 31px;
}

.nav-wrapper a {
    color: #1a464f;
    background-color: hsla(0, 0%, 100%, 0.1);
    border: 0;
    border-radius: 50px;
    box-shadow: inset 0 0 0 0 #e86464;
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 58px;
    padding-right: 40px;
    position: relative;
    text-decoration: none;
    text-indent: 67px;
    -webkit-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
    white-space: nowrap;
}

.nav-wrapper a:hover {
    background-color: #e86464;
    box-shadow: inset 0 0 0 50px #e86464;
    color: #fafafa;
    cursor: pointer;
    opacity: 1;
    transition: all 0.15s linear;
}
.nav-wrapper li:nth-of-type(1) a:before {
    background-image: url(../image/Gift-btn.png);
}
.nav-wrapper li:nth-of-type(2) a:before {
    background-image: url(../image/team-btn.png);
}
.nav-wrapper li:nth-of-type(3) a:before {
    background-image: url(../image/project-btn.png);
}
.nav-wrapper a:before {
    background-position: 50%;
    background-repeat: no-repeat;
    content: '';
    height: 32px;
    left: 20px;
    position: absolute;
    top: 16px;
    width: 32px;
    -webkit-transition: 0.15s -webkit-filter linear;
    -moz-transition: 0.15s -moz-filter linear;
    -moz-transition: 0.15s filter linear;
    -ms-transition: 0.15s -ms-filter linear;
    -o-transition: 0.15s -o-filter linear;
    transition: 0.15s filter linear, 0.15s -webkit-filter linear;
}
.nav-wrapper a:hover::before {
    filter: brightness(0) invert(1);
}
.grid {
    grid-gap: 20px;
    display: grid;
    gap: 40px;
}
.col2-3 {
    grid-template-columns: 2fr 3fr;
}
#gift-choice-selection {
    margin-top: 60px;
    margin-bottom: 60px;
}

.nft-holiday-gift {
    color: #1a464f;
}

#nft-holiday-gift-p {
    font-size: 16px;
    line-height: 30px;
    color: #2f6a76;
    font-weight: 400;
}

.calendar-links {
    display: table;
    margin: 0 auto;
}

.calendar-links-list {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    grid-auto-rows: 80px;
    list-style: none;
    margin: 20px 0;
}

.calendar-links-list button {
    background-color: #eff7f9;
    border-radius: 50px;
    border-color: #eff7f9;
    border-style: none;
    cursor: pointer;
    color: #2f6a76;
    font-size: 18px;
    font-weight: 900;
    line-height: 32px;
    text-align: center;
    width: 99px;
    height: 44px;
    display: inline;
    margin: 0;
    position: relative;
}

.calendar-links-list button:hover,
.calendar-btn.active {
    background-color: #e86464;
    color: white;
    transition: all 0.5s ease-out;
}

.calendar-btn-mobile {
    width: 100%;
    font-size: 18px;
    height: 65px;
    line-height: 65px;
    background-color: #e86464;
    border: 0;
    border-radius: 50px;
    color: #fafafa;
    position: relative;
    margin-top: 12px;
    text-align: left;
    text-indent: 26px;
}

.calendar-btn-mobile .arrow {
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    right: 9px;
    top: 7px;
    background-color: #d94f4f;
    border-radius: 50px;
    font-size: 42px;
    line-height: 44px;
    transform: rotate(90deg);
    text-align: center;
    text-indent: 0px;
    pointer-events: none;
}

.calendar-btn-mobile.active .arrow {
    transform: rotate(270deg);
}

.month-events {
    display: none;
    inline-size: 350px;
    list-style: none;
    line-height: 2.5;
    background-color: #f4f4f4;
    color: #2f6a76;
    border-radius: 20px;
    height: 500px;
    width: 100%;
    margin-top: 12px;
}

.month-events.active {
    display: block;
}

.date {
    display: block;
    padding: 30px;
}

.date p {
    margin-bottom: 20px;
}

.text {
    margin-left: 30px;
}
.date ul li {
    font-weight: bold;
    line-height: 1.5;
}

.holidays-text,
.holidays-title {
    margin-left: 30px;
}

#charity-gift-donations {
    margin: 50px 0;
    background-color: #eff7f9;
    padding: 50px;
    border-radius: 40px;
}

.charity-nft-collection {
    background-color: transparent;
    border: 2px solid #e86464;
    box-sizing: border-box;
    border-radius: 48px;
    width: 280px;
    height: 63px;
    font-size: 18px;
    line-height: 63px;
    font-weight: bold;
    position: relative;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.charity-nft-collection a {
    color: #bfd2d6;
    display: block;
    line-height: 63px;
    width: 280px;
    height: 63px;
}
/*
.charity-nft-collection:hover::before {
  content: "Services is comming soon";
  color: #1a464f;
  font-size: 18px;
  position: absolute;
  width: inherit;
  height: inherit;
  bottom: 0;
  left: 0;
  justify-content: center;
  text-align: center;
  background: #ffffff;
  border-radius: 48px;
  opacity: 0.95;
  box-shadow: 0px 4px 15px rgba(26, 70, 79, 0.3);
  transform: translateY(-80px);
}
.charity-nft-collection:hover::after {
  content: "";
  clear: both;
  border-top: 8px solid #fff;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  opacity: 0.95;
  position: absolute;
  height: 0;
  left: 46%;
  width: 0;
  top: -22px;
}
*/
.charity-nft-collection:hover {
    background-color: #e86464;
    border: 2px solid #e86464;
    color: white;
}
.charity-nft-collection:hover a{
  color: white;
}

#nft-charity {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: #2f6a76;
}

#our-team {
    margin: 50px 0;
    background-color: #fff;
    padding: 50px;
    border-radius: 20px;
    box-shadow: 0px 4px 39px #d6edf2;
    position: relative;
}
#our-team::after {
    content: '';
    background-image: url(../image/our-team.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 230px;
    height: 230px;
    display: block;
    top: 60%;
    left: -30px;
}
.our-team-img {
    width: 234px;
    height: 234px;
    float: left;
}

#our-team-text {
    color: #2f6a76;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    font-weight: 400;
}

.footer {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 40px;
    grid-gap: 40px;
    list-style: none;
    margin: 100px 0 80px;
}

#roadmap {
    background: #1e3952;
    border-radius: 20px;
    position: relative;
}
#roadmap ul {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #eff9fb;
    align-items: center;
    bottom: 0;
    display: grid;
    height: 100%;
    justify-content: center;
    position: absolute;
    left: 0;
    padding: 40px;
}
#roadmap ul li,
.date ul li {
    margin-left: 20px;
    position: relative;
}
#roadmap ul li:before,
.date ul li:first-of-type:before {
    color: #91d6f4;
    content: '\2022';
    display: inline-block;
    font-family: Poppins;
    font-weight: 700;
    font-size: 26px;
    margin-left: -20px;
    width: 1em;
}
#roadmap ul li:first-of-type:before,
.date ul li:first-of-type:before {
    color: #e86464;
}
.date ul li {
    margin-left: 30px;
}
.date ul li:first-of-type:before {
    margin-left: -28px;
}
#roadmap ul li::after {
    content: '';
    position: absolute;
    left: -16px;
    border-left: 1px solid #3b5b79;
    height: 126%;
    top: 33px;
    bottom: 0;
    width: 1px;
}
#roadmap ul li:last-of-type::after {
    border-left: none;
}
#Timeline {
    color: #1a464f;
    font-weight: 900;
    font-size: 30px;
    line-height: 62px;
    text-align: initial;
}

#contacts {
    color: #1a464f;
    font-weight: 900;
    font-size: 30px;
    line-height: 62px;
    text-align: initial;
}

#contact p {
    font-size: 16px;
    line-height: 30px;
    color: #2f6a76;
    margin-bottom: 30px;
}
footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.site-logo-footer {
    color: #fafafa;
}
.footer-copyright {
    background: #1e3952;
}
.footer-copyright .row {
    display: flex;
    justify-content: space-between;
}
footer .copyright {
    color: #828c98;
    font-size: 14px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media (min-width: 1300px) {
    .site-header::after {
        background-position: left center, center top, top right, 80% 100%;
        background-size: 220px, 165px, 300px, 365px;
    }
}
@media (max-width: 1025px) {
    .container {
        padding-left: 25px;
        padding-right: 25px;
    }
    .col2-3 {
        grid-template-columns: 1fr;
    }
    .site-header::after {
        background: url(../image/gift-top.png), url(../image/gift-bottom-right.png);
        background-repeat: no-repeat, no-repeat;
        background-position: center top, 118% 87%;
        background-size: 80px, 200px;
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        top: 0;
        right: 0;
    }
}
@media only screen and (max-width: 768px) {
    .main {
        width: 100%;
        margin: 0;
    }

    h1 {
        font-size: 32px;
        line-height: 1.4;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    #nft-gift-intro {
        font-size: 42px;
    }

    h2 {
        font-size: 28px;
        line-height: 1.4;
    }
    h3 {
        font-size: 26px;
        line-height: 1.4;
        margin: 10px 0 20px;
    }

    p {
        font-size: 16px;
        line-height: 30px;
        width: 100%;
    }

    .header-description {
        margin-bottom: 60px;
        font-size: 18px;
        color: #2f6a76;
        max-width: fit-content;
        line-height: 32px;
    }
    .calendar-links-list {
        display: inline-table;
    }

    #gift-choice-selection {
        margin: 30px;
    }
    #our-team::after {
        content: '';
        background-size: 130px;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 54%;
    }
    #nft-holiday-gift {
        background: #eff7f9;
        border-radius: 20px;
    }
    .charity-nft-collection {
        width: 100%;
    }
    #our-team,
    #charity-gift-donations,
    .nft-holiday-gift,
    #nft-holiday-gift {
        padding: 25px;
    }
    .footer,
    .col2-3 {
        grid-template-columns: 1fr;
    }
    .mobile-detector {
        display: none;
    }
    .calendar-content {
        margin-left: 0px;
    }

    .month-events {
        transition: all 0.5s ease-in-out;
        display: block;
        height: 0;
        overflow: hidden;
        margin-top: 0px;
    }

    .month-events.active {
        margin-top: 12px;
    }

    #calendar-links .month-events:nth-of-type(1) {
        height: 518px;
    }

    .holidays-text,
    .holidays-title {
        margin-left: 0;
    }
    .date {
        padding-left: 40px;
    }
    .date ul li:first-of-type:before {
        margin-left: -26px;
    }
    .date ul li {
        margin-left: 0;
    }
    .m-visible {
        display: initial;
    }

    .m-hidden {
        display: none !important;
    }
    .header-description {
        margin-bottom: 26px;
    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper ul {
        display: block;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .nav-wrapper li {
        width: 100%;
    }
    #roadmap {
        min-height: 580px;
    }
    footer .logo-wrapper {
        padding-bottom: 8px;
    }
    .footer-copyright .row {
        padding: 20px 0 40px;
        display: grid;
        justify-content: center;
        text-align: center;
    }
}
@media only screen and (max-width: 300px) {
    #roadmap ul {
        padding: 20px;
        font-size: 16px;
    }
}
